.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 85px;
	padding: 0 2rem;
	background: #eeeeee;
	color: #000;
	position: fixed;
	z-index: 999;
	width: 100%;
}

.logo img{
    width:60px;
   margin-right: 15px;
}
.logo-name{
	gap: 5px;
	display: flex;
	flex-direction: column;
	
	align-items: flex-start !important;
	color: #bf6871;
}
.logo-name h4{
	font-size: 18px;
	
}
.logo-name h5{
	font-size: 13px;
	
}
nav a:hover {
    color: #bf6871;
}

nav a {
    margin: 0 1.5rem;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

nav a::after, nav a.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -7px;
    width: 0%;
    height: 2px;
    background-color: #bf6871;
    transition: width 0.7s ease, left 0.3s ease;
}

nav a:hover::after, nav a.active::after {
    width: 100%;
    left: 0;
}

nav a:hover {
    color: #bf6871;
}

.header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: #bf6871;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

.header div,
nav {
    display: flex;
    align-items: center;
}

.navbar-button {
    border: 2px solid #ccc;
    padding: 5px 15px;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
}

.navbar-button:hover {
    color: #000;
    background-color: #ccc;
}

nav .active {
    color: #bf6871 !important;
}

  @media screen and (min-width:900px) {
	.nav-logo-btn{
		display: none!important;
	}
  }
@media screen and (min-width:901px) {
	.header .nav-btn {
		display: none!important;
	}
}

@media only screen and (max-width: 900px) {
	.header .nav-btn {
		visibility: visible;
		opacity: 1;
        width: 75px;
		
	}
	.responsive_nav .nav-btn{
		color: #fff;
	}
	.responsive_nav .logo-name h4{
		
		font-size: 14px;
		color: #fff;
	  }
	  .responsive_nav .logo-name h5{
		
		font-size: 11px;
		color: #fff;
	  }
	  .responsive_nav .logo-name{
		margin-left: 10px;
	  }
	.nav-logo-btn{
		position: absolute;
		top: 1.5rem;
		left: 1rem;
		font-size: 15px;
		letter-spacing: 2px;
		font-weight: 400!important;
		
		
	  }
	  
	  .nav-logo-btn img{
		width: 50px;
		
	  }
	.header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items:flex-start;
		justify-content: center;
		gap: 2rem;
		background-color: #bf6871;
		transition: 1s;
        z-index: 999;
	}
	

	.header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		bottom: 1.5rem;
		right: 1rem;
	}

	nav a {
		font-size: 1.2rem;
		color: #fff;
	}
    .logo img{
		width: 50px;
		/* margin: 8px 25px; */
		object-fit: cover;
		height: auto;
		margin-right: 5px !important;
		margin-left: 10px;
    }
	.logo-name h4{
		font-size: 15px;
	}
	.logo-name h5{
		font-size: 12px;
		color: #bf6871;
	}
    .header{
        padding: 0;
    }
	.navbar-button{
	border: none;
    padding: 0;
    margin: 0 2rem;
    color: #eee;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    font-size: 1.5em;
	}
	.navbar-button:hover{
		background: transparent;
		color: #ccc;
	}
	
nav .active {
    color: #000 !important;
}
nav a:hover {
    color: #000;
}


}
