/* About-Us page */
.about-us {
    width: 90%;
    margin: auto;
   padding-top: 110px;
    min-height: 79vh;
}

.about-us h2 {
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
  
    color: #bf6871;
}

.about-us p {
    margin-top: 30px;
    font-size: 16px;
    line-height: 30px;

}

.about-us span {
    color: #bf6871;
}
/* Terms & conditions */
.terms-conditions{
    width: 90%;
   
   padding: 110px 0;
   margin: 0 auto;
   
}
.terms-conditions h2{
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
   
    color: #bf6871;
}
.terms-conditions h3{
    margin-top: 30px;
    font-size: 19px;
    line-height: 30px;
    
    color: #bf6871;
}
.terms-conditions p{
    margin-top: 10px;
    font-size: 17px;
    line-height: 30px;
    
}
.terms-conditions ul{
    padding: 10px;
}
.terms-conditions li{
    padding: 3px;
}
/* Privacy policy */

.privacy-poicy{
    width: 90%;
     margin: 0 auto;
     padding: 100px 0;
    
}
.privacy-poicy h2{
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
    margin: 20px;
    color: #bf6871;
}
.policy-content p{
    margin-top: 10px;
    font-size: 17px;
    line-height: 30px;
    
}
.policy-content h3{
    margin-top: 30px;
    font-size: 19px;
    line-height: 30px;
    
    color: #bf6871;
}

/* Refund and Cancellation */
.refund{
    width: 90%;
    margin: 0 auto;
    min-height: 72vh;
    padding: 100px 0;
}
.refund h2{
    text-align: center;
    font-size: 31px;
    text-transform: uppercase;
    margin: 20px;
    color: #bf6871;
}
.refund p{
    margin-top: 10px;
    font-size: 17px;
    line-height: 30px;
}
.refund h3{
    margin-top: 30px;
    font-size: 19px;
    line-height: 30px;
 
    color: #bf6871;
}
/* Contact Us */
.contact-us{
    width: 70%;
    margin: 0 auto;
    min-height: 72vh;
   padding: 90px 0;
}
.contact-us h2{
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
    margin: 20px;
    color: #bf6871;
}
.contact-us p{
    margin-top: 10px;
    font-size: 17px;
    line-height: 30px;
   
}
.contact-us h3{
    margin-top: 30px;
    font-size: 19px;
    line-height: 30px;
   
  color: #bf6871;
}
@media screen and (max-width: 795px){
    .about-us{
        padding-bottom: 100px;
        padding-top: 100px;
    }
    .about-us h2 {
        font-size: 28px;
    }
    .about-us p{
        line-height: 33px;
        font-size: 16px;
    }
    
    .terms-conditions p {
        margin-top: 10px;
        font-size: 16px;
        line-height: 32px;
    }
    .terms-conditions h2 {
        font-size: 28px;
    }
    
    
    .privacy-poicy h2{
        font-size: 28px;

    }
    .policy-content p{
        font-size: 16px;
        line-height: 32px;
    }
    .refund h2{
        font-size: 28px;
    }
    .refund p{
        font-size: 16px;
        line-height: 32px;
    }
    .contact-us {
        width: 90%;
        margin: 0 auto;
        min-height: 70vh;
        padding-top: 80px;
    }
    .contact-us p {
        margin-top: 10px;
        font-size: 16px;
        line-height: 30px;
    }
    .contact-us h2{
        font-size: 28px;
    }
}