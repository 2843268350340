*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background: #fff;
  font-family: "Lato", sans-serif;
  color:black;
}
