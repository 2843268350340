

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #eeeeee;
  
    padding: 2px;
    text-align: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); 
}

.footer a {
    margin: 0 20px;
    text-decoration: none;
    color: #2a6496;
    font-size: 17px;
    font-weight: 500;
}

.footer p {
    padding: 10px;
}

.footer a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 795px) {
    .footer {
        height: auto;
        padding: 15px;
    }
    .footer a {
        margin: 10px;
    }
    
}
