.banner {
  padding-top: 30px;
}

.banner img {
  width: 100%;
  height: 75vh;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 10px;
  padding: 30px 50px;
  backdrop-filter: blur(5px);
  text-align: center;
  width: 92%;
  height: 52%;
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  background-color: #d9534f;
}

.hero-title {
  color: #bf6871;
  font-size: 32px;
  padding-bottom: 25px;
}

.herocontent {
  font-size: 17px;
  text-align: center;
  padding-bottom: 30px;
  line-height: 25px;
}

.content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  height: 500px;
  margin-bottom: 80px;
}

.table-container,
.login-container {
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  height: 100%;
}

.table-container {
  width: 70%;
  margin-right: 10px;
  border: 1px solid #ccc;

}

.login-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  margin-bottom: 100px;
}

.section-title {
  font-size: 19px;
  margin-bottom: 20px;
  background: #bf6871;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  width: 100%;
  text-transform: uppercase;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin: 30px 0;


}

.info-table th,
.info-table td {
  padding: 10px;
  text-align: left;
}

.info-table th {
  background-color: #fff;
  text-align: left;
  font-size: 17px;
  font-weight: bold;
}

.info-table td a {
  color: #3498db;
  text-decoration: none;

}

.info-table td {
  font-size: 14px;
  text-transform: uppercase;
}

/* Remove outer border and add bottom borders */
.info-table th,
.info-table td {
  border-bottom: 1px solid #ddd;
}

.info-table tr:nth-child(2n-1) {
  background-color: #f4f4f4;
}

.info-table tr:nth-child(2n) {
  background-color: #fff;
}

.login-button {
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 16px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100%;
  text-align: center;

}

.line-separate {
  width: 100%;
  height: 1.5px;
  background-color: #ccc;
  margin: 10px;
  border-radius: 5px;

}

.buttons {
  width: 100%;

  display: flex;
  justify-content: space-around;
  flex-direction: column;

}

.buttons h3 {
  color: #000;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: normal;
}

.exam-form,
.enrolment {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

}

/* .enrolment .home-buttons {
  background-color: rgb(179, 6, 6);
} */

.home-buttons {
  width: 60%;
  padding: 10px;
  border: 1px solid #ccc;
  color: #fff;
  font-size: 15px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: "Lato", sans-serif;
  text-decoration: none;
  background: #428bca;
}

.home-buttons:hover {

  color: #fff;
  text-decoration: none;
}


@media only screen and (max-width: 900px) {
  .banner {
    padding-top: 20px;
  }

  .banner img {
    height: 50vh;
  }

  .overlay {
    top: 31%;
  }

  .overlay {
    width: 85%;
    height: 36%;
    padding: 15px;
  }

  .hero-title {
    font-size: 21px;

  }

  .herocontent {
    font-size: 15px;

  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: auto;
    margin-bottom: 0;
  }

  .table-container {
    width: 100%;
    padding: 10px;
  }

  .login-container {
    width: 100%;
    padding: 10px;
  }
}

.disabled-link {
  pointer-events: none;
  color: #ccc;
  /* Light grey text */
  background-color: #f0f0f0;
  /* Light background */
  border: 1px solid #ccc;
  /* Light border */
  opacity: 0.6;
  /* Slight transparency */
  cursor: not-allowed;
  /* Change cursor to show it's disabled */
}